{
  "faq": [
    {
      "id": 1,
      "question": "@HOME-SHORT-FAQ-PERGUNTA-1",
      "answer": {
        "html": "@HOME-SHORT-FAQ-RESPOSTA-1"
      }
    },
    {
      "id": 2,
      "question": "@HOME-SHORT-FAQ-PERGUNTA-2",
      "answer": {
        "html": "@HOME-SHORT-FAQ-RESPOSTA-2"
      }
    },
    {
      "id": 3,
      "question": "@HOME-SHORT-FAQ-PERGUNTA-3",
      "answer": {
        "html": "@HOME-SHORT-FAQ-RESPOSTA-3"
      }
    }
  ]
}
