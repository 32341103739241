{
  "quickLinks": [
    {
      "name": "@QUICK-ACCESS-LABEL-4",
      "url": "agendamento/consultar",
      "channel": "Quero Comgás",
      "iconClass": "icon-calendario_check-1",
      "actionEvent": "agendamento videochamada"
    },
    {
      "name": "@QUICK-ACCESS-LABEL-1",
      "url": "?utm_source=qc&utm_medium=referral&utm_campaign=segunda-via&utm_content=botao",
      "channel": "Comgás Virtual",
      "iconClass": "icon-boleto_gerar"
    },
    {
      "name": "@QUICK-ACCESS-LABEL-2",
      "url": "liga-religa/triagem/?utm_source=qc&utm_medium=referral&utm_campaign=religa&utm_content=botao",
      "channel": "Comgás Virtual",
      "iconClass": "icon-chama_comgas"
    },
    {
      "name": "@QUICK-ACCESS-LABEL-3",
      "url": "trocaTitularidade/?utm_source=qc&utm_medium=referral&utm_campaign=troca-titularidade&utm_content=botao",
      "channel": "Comgás Virtual",
      "iconClass": "icon-troca-titularidade"
    }
  ]
}
