import { Component, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import {
  ITestimonialsTextCard,
  TestimonialsTextCardsComponent,
} from '@shared/components';

export interface ITestimonialsData {
  testimonials: ITestimonialsTextCard[];
}

@Component({
  selector: 'qsc-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
  standalone: true,
  imports: [TestimonialsTextCardsComponent, TranslateModule],
})
export class TestimonialsComponent {
  @Input() data: ITestimonialsData = {} as ITestimonialsData;
}
