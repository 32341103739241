import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { IDevice, IOptimizedImageData } from '@models/index';
import { PictureComponent } from '@shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { BootstrapService } from '@core/ui/bootstrap/bootstrap.service';

interface IAdvantagesData {
  title: IDevice;
  image: IOptimizedImageData;
  items: {
    title: string;
    description: string;
    icon: string;
    iconSvg: string;
  }[];
}

@Component({
  selector: 'qsc-advantages',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    PictureComponent,
    TranslateModule,
  ],
  templateUrl: './advantages.component.html',
  styleUrls: ['./advantages.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvantagesComponent {
  @Input() data: IAdvantagesData = {} as IAdvantagesData;
  panelOpenId: number | null = null;

  constructor(public readonly bootstrap: BootstrapService) {}

  onOpened(id: number): void {
    this.panelOpenId = id;
  }

  onClosed(id: number): void {
    if (this.panelOpenId === id) {
      this.panelOpenId = null;
    }
  }
}
