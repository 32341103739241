<section
  id="testimonials"
  class="testimonials container container-fhd px-0 px-sm-3"
>
  <h2
    class="testimonials__title px-3 px-sm-0"
    [innerHTML]="'@HOME-TESTIMONIALS-TITLE' | translate"
  ></h2>
  <div class="px-2 px-sm-0">
    <qsc-testimonials-text-cards
      [data]="data.testimonials"
    ></qsc-testimonials-text-cards>
  </div>
</section>
