import {
  Component,
  OnInit,
  AfterViewInit,
  ViewEncapsulation,
  Renderer2,
  Inject,
} from '@angular/core';
import { environment } from '@env';
import { loadCordovaUtils, SeoJson } from '@core/index';
import {
  UiAppFacade,
  RoutingAppFacade,
  AnalyticsAppFacade,
  StorageAppFacade,
  ServicesAppFacade,
} from '@shared/services';
import { ICriptografiaOutput } from '@models/index';
import { paserQueryString } from '@shared/utils';
import { IAddress } from '@modules/address-validation/models/address-validation';
import {
  BackToTopComponent,
  ChatMessageComponent,
  FooterComponent,
  HeaderComponent,
} from '@shared/components';
import { RouterOutlet } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    ChatMessageComponent,
    BackToTopComponent,
  ],
  providers: [
    UiAppFacade,
    RoutingAppFacade,
    AnalyticsAppFacade,
    StorageAppFacade,
    ServicesAppFacade,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterViewInit {
  private readonly hostName = SeoJson.hostname;
  showHeader = false;
  window: (Window & typeof globalThis) | null = null;

  constructor(
    private readonly uiAppFacade: UiAppFacade,
    private readonly routingAppFacade: RoutingAppFacade,
    private readonly analyticsAppFacade: AnalyticsAppFacade,
    private readonly storageAppFacade: StorageAppFacade,
    private readonly servicesAppFacade: ServicesAppFacade,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.uiAppFacade.bootstrap.configure();
    this.window = this.document.defaultView;
  }

  ngOnInit(): void {
    this.initSeoSchemas();
    this.initTranslation();
    this.initPlatformDependentServices();
    this.initRouteEvents();
    this.addMetaTags();
    this.initAddressValidationFlow();
    this.uiAppFacade.pageScrollerService.handle();
  }

  ngAfterViewInit(): void {
    if (this.servicesAppFacade.platformChecker.isBrowser()) {
      this.saveMediaParameters();
      this.applyWebMessengerStyle();
      this.loadGoogleMapsScript();
      this.uiAppFacade.bootstrap.enableBootstrapTooltips();
    }
  }

  initSeoSchemas() {
    this.analyticsAppFacade.seoService.addSchema(SeoJson.schemas.Website);
  }

  initTranslation() {
    this.servicesAppFacade.translateService.setDefaultLang('pt-br');
    this.servicesAppFacade.translateService.use('pt-br').subscribe({});
  }

  private initPlatformDependentServices(): void {
    if (this.servicesAppFacade.platformChecker.isBrowser()) {
      this.servicesAppFacade.chatService.loadChat();
      loadCordovaUtils();
      this.analyticsAppFacade.googleAnalyticsService.startCapturing();
    }
  }

  private initRouteEvents(): void {
    this.routingAppFacade.routerEvents
      .getEvents()
      .subscribe(({ urlAfterRedirects }) => {
        this.analyticsAppFacade.seoService.updateMetaTags();
        this.analyticsAppFacade.seoService.updateCanonicalUrl(
          this.hostName + urlAfterRedirects
        );
        this.routingAppFacade.urlService.setCurrentUrlPath(urlAfterRedirects);
        this.servicesAppFacade.loadingService.hideLoading();
        this.handleShowHeader(urlAfterRedirects);
        this.handleAppLoaded();
      });
  }

  private handleAppLoaded() {
    if (this.servicesAppFacade.platformChecker.isBrowser()) {
      const loadingElement =
        this.window?.document.getElementById('loadingComgas');
      if (loadingElement) {
        this.renderer.addClass(loadingElement, 'loaded');
      }
    }
  }

  private handleShowHeader(url: string): void {
    const routesWithoutHeader = [
      'selecionar-segmento',
      'validar-endereco',
      'selecionar-equipamentos',
      'agendamento',
      'protocolo',
      'feedback',
    ];
    const urlWithoutQueryParams = url.split('?')[0];
    const route = urlWithoutQueryParams.split('/')[1];
    this.showHeader = !routesWithoutHeader.includes(route);
  }

  private addMetaTags(): void {
    this.analyticsAppFacade.seoService.addMetaTags(SeoJson.metatags.twitter);
    this.analyticsAppFacade.seoService.addMetaTags(SeoJson.metatags.facebook);
    this.analyticsAppFacade.seoService.addMetaTags(SeoJson.metatags.googlePlus);
  }

  private saveMediaParameters(): void {
    this.analyticsAppFacade.googleTagManager.saveMediaParamaters();
  }

  private applyWebMessengerStyle(): void {
    this.servicesAppFacade.chatService.applyChatIframeStyle();
    this.storageAppFacade.cookiesService.applyConfig();
  }

  initAddressValidationFlow(): void {
    this.routingAppFacade.activatedRoute.queryParamMap.subscribe(
      (params: any) => {
        let addressParams = params.has('endereco')
          ? params.get('endereco')
          : null;

        if (!addressParams) {
          return;
        }

        addressParams = encodeURI(addressParams).replace(/%20/g, '+');

        this.servicesAppFacade.cryptographService
          .decrypt({ texto: addressParams })
          .subscribe((queryString: ICriptografiaOutput) => {
            if (!queryString?.texto) return;
            const address = paserQueryString<IAddress>(
              decodeURI(queryString.texto)
            );

            if (!address) return;

            this.storageAppFacade.sessionService.saveData<IAddress>(
              'address',
              address
            );
            this.routingAppFacade.router.navigate(['/validar-endereco']);
          });
      }
    );
  }

  private loadGoogleMapsScript(): void {
    const googleMapsScript = this.renderer.createElement('script');
    googleMapsScript.text = `
      (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=\`https://maps.\${c}apis.com/maps/api/js?\`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
        v: "weekly",
        key: '${environment.googleApiKey}',
        language: "pt-BR",
      });
    `;
    this.renderer.appendChild(document.body, googleMapsScript);
  }
}
