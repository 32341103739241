{
  "banners": {
    "campaign": {
      "image": {
        "alt": "@HOME-BANNER-CAMPAIGN-IMAGE-ALT",
        "breakpoints": {
          "0": {
            "png": "assets/home/img/banners/campaign-banner/botijao.png",
            "webp": "assets/home/img/banners/campaign-banner/botijao.webp"
          },
          "375": {
            "png": "assets/home/img/banners/campaign-banner/botijao-375.png",
            "webp": "assets/home/img/banners/campaign-banner/botijao-375.webp"
          },
          "768": {
            "png": "assets/home/img/banners/campaign-banner/botijao-768.png",
            "webp": "assets/home/img/banners/campaign-banner/botijao-768.webp"
          },
          "992": {
            "png": "assets/home/img/banners/campaign-banner/botijao-992.png",
            "webp": "assets/home/img/banners/campaign-banner/botijao-992.webp"
          }
        }
      },
      "texts": [
        "@HOME-BANNER-CAMPAIGN-TEXT-1",
        "@HOME-BANNER-CAMPAIGN-TEXT-2",
        "@HOME-BANNER-CAMPAIGN-TEXT-3",
        "@HOME-BANNER-CAMPAIGN-TEXT-4"
      ],
      "buttons": ["@HEADER-CONTRATAR-GAS-ENCANADO"]
    }
  }
}
