import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  Injectable,
  isDevMode,
  LOCALE_ID,
  Renderer2,
  TransferState,
} from '@angular/core';
import {
  PreloadAllModules,
  provideRouter,
  RouterStateSnapshot,
  TitleStrategy,
  withInMemoryScrolling,
  withPreloading,
  withViewTransitions,
} from '@angular/router';

import { routes } from './app.routes';
import { Title, ɵDomRendererFactory2 } from '@angular/platform-browser';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { SeoJson } from '@core/seo';
import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
  provideStore,
} from '@ngrx/store';
import { fromUser, IAppState } from '@core/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { APP_BASE_HREF } from '@angular/common';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import {
  MultiTranslateBrowserLoader,
  translateMultiBrowserLoaderFactory,
} from '@core/translation';
import { i18nPaths } from '@assets/i18n-paths';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler/global-error-handler.service';

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title) {
      this.translateService.get(title).subscribe((translatedTitle) => {
        this.title.setTitle(`${SeoJson.sitename} | ${translatedTitle}`);
      });
    } else {
      this.title.setTitle(SeoJson.sitename);
    }
  }
}

const reducers: ActionReducerMap<IAppState> = {
  user: fromUser.userReducer,
};

const metaReducers: Array<MetaReducer<IAppState>> = [];

export function localStorageSyncReducer(
  reducer: ActionReducer<IAppState>
): ActionReducer<IAppState> {
  return localStorageSync({
    keys: ['user'],
    rehydrate: true,
    storage: sessionStorage,
  })(reducer);
}

if (typeof window !== 'undefined') {
  metaReducers.push(localStorageSyncReducer);
}

export function appLoaderFactory(
  http: HttpClient,
  transferState: TransferState
): MultiTranslateBrowserLoader {
  return translateMultiBrowserLoaderFactory('common', http, transferState, [
    ...i18nPaths.commonPaths,
  ]);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
      }),
      withPreloading(PreloadAllModules),
      withViewTransitions()
    ),
    provideHttpClient(withFetch()),
    provideAnimations(),
    provideStore(reducers, { metaReducers }),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    provideToastr(),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'pt-br',
        loader: {
          provide: TranslateLoader,
          useFactory: appLoaderFactory,
          deps: [HttpClient, TransferState],
        },
      }),
      ModalModule.forRoot()
    ),
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
    {
      provide: Renderer2,
      useFactory: (domRendererFactory: ɵDomRendererFactory2) =>
        domRendererFactory.createRenderer(null, null),
      deps: [ɵDomRendererFactory2],
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
  ],
};
