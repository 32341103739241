{
  "title": {
    "mobile": "@HOME-VANTAGENS-TITULO",
    "desktop": "@HOME-VANTAGENS-TITULO-DESKTOP"
  },
  "image": {
    "alt": "@HOME-VANTAGENS-IMAGE-ALT",
    "title": "@HOME-VANTAGENS-IMAGE-TITLE",
    "breakpoints": {
      "0": {
        "png": "assets/home/img/advantages/piscina-aquecida-a-gás.png",
        "webp": "assets/home/img/advantages/piscina-aquecida-a-gás.webp"
      },
      "1200": {
        "png": "assets/home/img/advantages/piscina-aquecida-a-gás-1366.png",
        "webp": "assets/home/img/advantages/piscina-aquecida-a-gás-1366.webp"
      },
      "1400": {
        "png": "assets/home/img/advantages/piscina-aquecida-a-gás-1920.png",
        "webp": "assets/home/img/advantages/piscina-aquecida-a-gás-1920.webp"
      }
    }
  },
  "items": [
    {
      "title": "@HOME-VANTAGENS-CARD-1-TITULO",
      "description": "@HOME-VANTAGENS-CARD-1-DESCRICAO",
      "icon": "icon-eco-thin-folha-fina",
      "iconSvg": ""
    },
    {
      "title": "@HOME-VANTAGENS-CARD-2-TITULO",
      "description": "@HOME-VANTAGENS-CARD-2-DESCRICAO",
      "icon": "icon-seguranca",
      "iconSvg": ""
    },
    {
      "title": "@HOME-VANTAGENS-CARD-3-TITULO",
      "description": "@HOME-VANTAGENS-CARD-3-DESCRICAO",
      "icon": "icon-ideia",
      "iconSvg": ""
    },
    {
      "title": "@HOME-VANTAGENS-CARD-4-TITULO",
      "description": "@HOME-VANTAGENS-CARD-4-DESCRICAO",
      "icon": "icon-mao_aperto",
      "iconSvg": ""
    }
  ]
}
