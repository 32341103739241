{
  "testimonials": [
    {
      "id": 1,
      "name": "@HOME-TESTIMONIALS-CARD-1-CLIENT",
      "role": "@HOME-TESTIMONIALS-CARD-1-ASSIGNMENT",
      "description": "@HOME-TESTIMONIALS-CARD-1-DESCRIPTION",
      "image": {
        "alt": "@HOME-TESTIMONIALS-CARD-1-IMG-ALT",
        "title": "@HOME-TESTIMONIALS-CARD-1-IMG-TITLE",
        "mobile": {
          "webp": "assets/home/img/testimonials/roberto-pinheiro-socio-de-restaurante-e-cliente-quero-comgas-mobile.webp",
          "png": "assets/home/img/testimonials/roberto-pinheiro-socio-de-restaurante-e-cliente-quero-comgas-mobile.png"
        },
        "desktop": {
          "webp": "assets/home/img/testimonials/roberto-pinheiro-socio-de-restaurante-e-cliente-quero-comgas-desktop.webp",
          "png": "assets/home/img/testimonials/roberto-pinheiro-socio-de-restaurante-e-cliente-quero-comgas-desktop.png"
        }
      }
    },
    {
      "id": 2,
      "name": "@HOME-TESTIMONIALS-CARD-2-CLIENT",
      "role": "@HOME-TESTIMONIALS-CARD-2-ASSIGNMENT",
      "description": "@HOME-TESTIMONIALS-CARD-2-DESCRIPTION",
      "image": {
        "alt": "@HOME-TESTIMONIALS-CARD-2-IMG-ALT",
        "title": "@HOME-TESTIMONIALS-CARD-2-IMG-TITLE",
        "mobile": {
          "webp": "assets/home/img/testimonials/rafaella-hanna-sindica-de-predio-e-cliente-quero-comgas-mobile.webp",
          "png": "assets/home/img/testimonials/rafaella-hanna-sindica-de-predio-e-cliente-quero-comgas-mobile.png"
        },
        "desktop": {
          "webp": "assets/home/img/testimonials/rafaella-hanna-sindica-de-predio-e-cliente-quero-comgas-desktop.webp",
          "png": "assets/home/img/testimonials/rafaella-hanna-sindica-de-predio-e-cliente-quero-comgas-desktop.png"
        }
      }
    },
    {
      "id": 3,
      "name": "@HOME-TESTIMONIALS-CARD-3-CLIENT",
      "role": "@HOME-TESTIMONIALS-CARD-3-ASSIGNMENT",
      "description": "@HOME-TESTIMONIALS-CARD-3-DESCRIPTION",
      "image": {
        "alt": "@HOME-TESTIMONIALS-CARD-3-IMG-ALT",
        "title": "@HOME-TESTIMONIALS-CARD-3-IMG-TITLE",
        "mobile": {
          "webp": "assets/home/img/testimonials/nilson-morador-de-apartamento-e-cliente-quero-comgas-mobile.webp",
          "png": "assets/home/img/testimonials/nilson-morador-de-apartamento-e-cliente-quero-comgas-mobile.png"
        },
        "desktop": {
          "webp": "assets/home/img/testimonials/nilson-morador-de-apartamento-e-cliente-quero-comgas-desktop.webp",
          "png": "assets/home/img/testimonials/nilson-morador-de-apartamento-e-cliente-quero-comgas-desktop.png"
        }
      }
    }
  ]
}
