<section class="advantages container px-3 container-fhd">
  <div class="row row-cols-1 row-cols-lg-2 g-4">
    <div class="col col-lg-5 col-xxl-6">
      <div class="advantages__title-image">
        <h2
          class="advantages__title"
          [innerHTML]="data.title.mobile | translate"
        ></h2>
        <div class="advantages__image">
          <qsc-picture
            [breakpoints]="data.image.breakpoints"
            [alt]="data.image.alt"
            [title]="data.image.title"
            [fullWidth]="true"
            [fullHeight]="true"
            [cover]="true"
          ></qsc-picture>
        </div>
      </div>
    </div>

    <div class="col col-lg-7 col-xxl-6">
      <mat-accordion
        class="advantages__items"
        [multi]="bootstrap.isMatched('lg')"
      >
        <mat-expansion-panel
          *ngFor="let item of data.items; let i = index"
          hideToggle
          [expanded]="
            bootstrap.isMatched('lg') ||
            (panelOpenId === i && !bootstrap.isMatched('lg'))
          "
          (opened)="onOpened(i)"
          (closed)="onClosed(i)"
          [disabled]="bootstrap.isMatched('lg')"
          class="advantages__item"
          [ngClass]="{
            'advantages__item--active':
              panelOpenId === i && !bootstrap.isMatched('lg'),
            'advantages__item--desktop': bootstrap.isMatched('lg')
          }"
        >
          <span
            class="advantages__item-icon"
            [ngClass]="{
              'advantages__item-icon--active': panelOpenId === i,
              'd-none': !bootstrap.isMatched('lg')
            }"
          >
            <i aria-hidden="true" [class]="item.icon"></i>
          </span>
          <mat-expansion-panel-header class="advantages__item-header">
            <mat-panel-title class="advantages__item-title">
              <div class="advantages__item-title-container">
                <span
                  class="advantages__item-icon"
                  [ngClass]="{
                    'advantages__item-icon--active': panelOpenId === i,
                    'd-none': bootstrap.isMatched('lg')
                  }"
                >
                  <i aria-hidden="true" [class]="item.icon"></i>
                </span>
                <h3
                  class="advantages__item-title-text mb-0"
                  [innerHTML]="item.title | translate"
                ></h3>
              </div>
              <span
                class="advantages__item-toggle-icon"
                [ngClass]="{
                  'advantages__item-toggle-icon--rotate': panelOpenId === i,
                  'd-none': bootstrap.isMatched('lg')
                }"
              >
                <i aria-hidden="true" class="icon-sinal_somar"></i
              ></span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div
            class="advantages__item-description"
            [innerHTML]="item.description | translate"
          ></div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</section>
