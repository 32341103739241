{
  "title": "@HOME-ABOUT-TITLE",
  "cards": [
    {
      "title": "@HOME-ABOUT-CARD-1-TITLE",
      "description": "@HOME-ABOUT-CARD-1-DESCRIPTION",
      "iconClass": "icon-casa_home",
      "variant": "turquesa",
      "toUrl": "/residencias"
    },
    {
      "title": "@HOME-ABOUT-CARD-2-TITLE",
      "description": "@HOME-ABOUT-CARD-2-DESCRIPTION",
      "iconClass": "icon-predio",
      "variant": "green",
      "toUrl": "/predios"
    }
  ],
  "stepsTitle": "@HOME-ABOUT-STEPS-TITLE",
  "stepsImages": {
    "mobile": "assets/home/img/svg/about/passo-a-passo-mobile.svg",
    "desktop": "assets/home/img/svg/about/passo-a-passo-desktop.svg"
  },
  "steps": [
    "@HOME-ABOUT-STEPS-ITEM-1",
    "@HOME-ABOUT-STEPS-ITEM-2",
    "@HOME-ABOUT-STEPS-ITEM-3",
    "@HOME-ABOUT-STEPS-ITEM-4",
    "@HOME-ABOUT-STEPS-ITEM-5",
    "@HOME-ABOUT-STEPS-ITEM-6"
  ],
  "obs": "@HOME-ABOUT-STEPS-OBS",
  "numbers": [
    {
      "value": "@HOME-ABOUT-ITEM-NUMBER-1",
      "legend": "@HOME-ABOUT-ITEM-LEGEND-1",
      "icon": "icon-mao_aperto"
    },
    {
      "value": "@HOME-ABOUT-ITEM-NUMBER-2",
      "isPercentage": true,
      "legend": "@HOME-ABOUT-ITEM-LEGEND-2",
      "icon": "icon-visto_check"
    },
    {
      "value": "@HOME-ABOUT-ITEM-NUMBER-3",
      "legend": "@HOME-ABOUT-ITEM-LEGEND-3",
      "icon": "icon-localizacao_gps"
    }
  ]
}
